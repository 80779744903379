import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Card, Spinner } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment";
import { getParameterByName } from "../../../Utils/location";
import {
  apiCheckinAssignedVolunteer,
  apiCheckInfoAssignedVolunteer,
  apiCheckoutAssignedVolunteer,
  apiGetProgramme,
} from "../../../datasources/volunteerRequestRepo";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function AttendanceVolunteerRequestPage() {
  const [idEvent, setIdEvent] = useState(null);
  const [dataEvent, setDataEvent] = useState(null);
  const [email, setEmail] = useState("");
  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    message: null,
  });
  const [resAttendance, setResAttendance] = useState(null);
  const [canCheckIn, setCanCheckIn] = useState(null);
  const [lastCheckinDate, setLastCheckinDate] = useState(null);
  const [lastCheckoutDate, setLastCheckoutDate] = useState(null);

  useEffect(() => {
    let idEvent = getParameterByName("id");
    setIdEvent(idEvent);
    getProgramme(idEvent);
  }, []);

  function getProgramme(idEvent) {
    setFormState({
      ...formState,
      loading: true,
    });
    apiGetProgramme({ id: idEvent })
      .then((res) => {
        console.log(res);
        setDataEvent(res.data.volunteerRequest);
        setFormState({
          ...formState,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setFormState({
          ...formState,
          loading: false,
          error: true,
          message: err?.response?.data?.message,
        });
      });
  }

  function checkAssignedVolunteer(payload) {
    apiCheckInfoAssignedVolunteer(payload)
      .then((res) => {
        console.log(
          res?.data?.volunteerRequest?.attendances[
            res?.data?.volunteerRequest?.attendances?.length - 1
          ]?.Checked_Out__c
        );
        setFormState({
          ...formState,
          loading: false,
          error: false,
          message:
            moment(
              res?.data?.volunteerRequest?.attendances[
                res?.data?.volunteerRequest?.attendances?.length - 1
              ]?.Checked_In__c
            ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
            res?.data?.volunteerRequest?.attendances[
              res?.data?.volunteerRequest?.attendances?.length - 1
            ]?.Checked_Out__c !== null &&
            res?.data?.volunteerRequest?.attendances.length > 0
              ? "You already checkin today"
              : res?.data?.volunteerRequest?.message,
        });
        setCanCheckIn(res?.data?.volunteerRequest?.canCheckIn);
        setLastCheckinDate(
          res?.data?.volunteerRequest?.attendances[
            res?.data?.volunteerRequest?.attendances?.length - 1
          ]?.Checked_In__c
        );
        setLastCheckoutDate(
          res?.data?.volunteerRequest?.attendances[
            res?.data?.volunteerRequest?.attendances?.length - 1
          ]?.Checked_Out__c
        );
        setResAttendance("exists");
      })
      .catch((err) => {
        setFormState({
          ...formState,
          loading: false,
          error: true,
          message: err?.response?.data?.message,
        });
      });
  }

  function submitData(e) {
    e.preventDefault();
    let payload = {
      volunteerRequestID: idEvent,
      email: email,
    };
    setFormState({
      ...formState,
      loading: true,
    });
    if (canCheckIn === null) {
      checkAssignedVolunteer(payload);
    } else {
      if (canCheckIn === true) {
        apiCheckinAssignedVolunteer(payload)
          .then((res) => {
            console.log(res);
            setFormState({
              ...formState,
              loading: false,
              error: false,
              message: res?.data?.volunteerRequest?.message,
            });
            setResAttendance(res?.data?.volunteerRequest);
            setCanCheckIn(false);
            setLastCheckinDate(res?.data?.volunteerRequest?.checkinTime);
          })
          .catch((err) => {
            setFormState({
              ...formState,
              loading: false,
              error: true,
              message: err?.response?.data?.message,
            });
          });
      } else {
        apiCheckoutAssignedVolunteer(payload)
          .then((res) => {
            console.log(res);
            setFormState({
              ...formState,
              loading: false,
              error: false,
              message: res?.data?.volunteerRequest?.message,
            });
            setResAttendance(res?.data?.volunteerRequest);
            setLastCheckoutDate(res?.data?.volunteerRequest?.checkOutTime);
          })
          .catch((err) => {
            setFormState({
              ...formState,
              loading: false,
              error: true,
              message: err?.response?.data?.message,
            });
          });
      }
    }
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(assets/images/hold_heart.png)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "45rem", height: "25rem" }}>
        <Card.Header>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={"assets/images/logo-interaktiv.png"}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Attendance Volunteer</h5>
            </div>
            <div className="col-lg-2" />
          </div>
        </Card.Header>
        <Card.Body>
          <CONTAINER>
            {dataEvent !== null ? (
              <Form onSubmit={submitData}>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.Name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.Start_Date__c).format(
                          "DD MMMM YYYY"
                        )}
                        {dataEvent?.Start_Time__c
                          ? " " + dataEvent?.Start_Time__c?.substring(0, 5)
                          : ""}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.End_Date__c).format("DD MMMM YYYY")}
                        {dataEvent?.End_Time__c
                          ? " " + dataEvent?.End_Time__c?.substring(0, 5)
                          : ""}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Volunteer Location
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {dataEvent?.Location_Address__c !== "" &&
                      dataEvent?.Location_Address__c?.includes("http") ? (
                        <a
                          href={dataEvent?.Location_Address__c}
                          target="_blank"
                        >
                          <Form.Label
                            style={{
                              color: "blue",
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {dataEvent?.Location_Address__c}
                          </Form.Label>
                        </a>
                      ) : (
                        <Form.Label>
                          {dataEvent?.Location_Address__c
                            ? dataEvent?.Location_Address__c
                            : "N/A"}
                        </Form.Label>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className={
                    "align-items-center justify-content-center mb-5 mt-3"
                  }
                >
                  <Col
                    lg={12}
                    xs={12}
                    className={
                      "align-items-center justify-content-center d-flex"
                    }
                  >
                    <Form.Text
                      as={Row}
                      className={`${
                        formState.error ? "text-danger" : "text-success"
                      } mt-0 mb-0 align-items-center justify-content-center d-flex`}
                    >
                      {formState.message}
                    </Form.Text>
                  </Col>
                  <Col
                    lg={8}
                    xs={12}
                    className={
                      "align-items-center justify-content-center d-flex mt-2"
                    }
                  >
                    <Form.Control
                      type="email"
                      placeholder="email"
                      style={{ width: "50%" }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      disabled={resAttendance !== null}
                    />
                  </Col>
                  <Col
                    lg={8}
                    xs={12}
                    className={
                      "align-items-center justify-content-center d-flex mt-2"
                    }
                  >
                    <Button
                      variant={
                        canCheckIn === null
                          ? "primary"
                          : canCheckIn === true
                          ? "success"
                          : "danger"
                      }
                      style={{
                        width: "auto",
                        maxWidth: "50%",
                        minWidth: "50%",
                        display:
                          canCheckIn === false &&
                          moment(lastCheckinDate).format("YYYY-MM-DD") ===
                            moment().format("YYYY-MM-DD") &&
                          moment(lastCheckoutDate).format("YYYY-MM-DD") ===
                            moment().format("YYYY-MM-DD")
                            ? "none"
                            : "block",
                      }}
                      type="submit"
                      disabled={formState.loading}
                    >
                      {canCheckIn === null
                        ? "Submit"
                        : canCheckIn === true
                        ? "Check In"
                        : "Check Out"}
                      {formState.loading && (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </Card.Body>
      </Card>
    </div>
  );
}
