// import { methodService, apiService } from "./apiServiceVolunteer";
import { methodService, apiService } from "./apiVolunter";

export const URL = {
  VR: "/volunteerRequest",
  ASSIGNED_VOLUNTEER: "/assignedVolunteers",
};

export const apiGetProgramme = (data) => {
  return apiService(URL.VR + `/${data.id}`, methodService.GET, null, null);
};

export const apiGetAssignedVolunteer = (data) => {
  return apiService(
    URL.ASSIGNED_VOLUNTEER + `/${data.id}`,
    methodService.GET,
    null,
    null
  );
};

export const apiResponseAssignedVolunteer = (data) => {
  return apiService(
    URL.ASSIGNED_VOLUNTEER + `/${data.id}/responseInvitation`,
    methodService.PATCH,
    data,
    null
  );
};

export const apiCheckinAssignedVolunteer = (data) => {
  return apiService(URL.VR + `/checkIn`, methodService.POST, data, null);
};

export const apiCheckoutAssignedVolunteer = (data) => {
  return apiService(URL.VR + `/checkOut`, methodService.POST, data, null);
};

export const apiCheckInfoAssignedVolunteer = (data) => {
  return apiService(URL.VR + `/checkInfo`, methodService.POST, data, null);
};
