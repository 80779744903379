import React from "react";
import classes from "./Countdown.module.css";

const Countdown = () => {
  return (
    <div className={classes.Root}>
      <div className={classes.Card}>
        <p className={classes.Title}>Countdown</p>
        <div className={classes.Countdown}>
          <div className={classes.Counter}>
            <div className={classes.Number}>12</div>
            <p className={classes.Unit}>Days</p>
          </div>
          <div className={classes.Counter}>
            <div className={classes.Number}>08</div>
            <p className={classes.Unit}>Hours</p>
          </div>
          <div className={classes.Counter}>
            <div className={classes.Number}>36</div>
            <p className={classes.Unit}>Minutes</p>
          </div>
          <div className={classes.Counter}>
            <div className={classes.Number}>54</div>
            <p className={classes.Unit}>Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
